import React, {useState} from "react";
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';


const buttons = [
    <Button variant="outlined" key="two"><label className="tab-button">运营团队</label></Button>,
    <Button variant="outlined" key="two"><label className="tab-button">教学团队</label></Button>
];

function TeamOps(props) {
    return (
        <Box>
            <Box className="col-md-10 col-md-offset-1" alignItems="center">
                <Box className="col-md-12 col-md-offset-2" alignItems="center">
                    {props.data
                        ? props.data
                            .filter(d => d.tier === 1)
                            .map((d, i) => (
                                <div key={`${d.name}-${i}`} className="col-md-4 col-sm-6 team">
                                    <div className="thumbnail">
                                        {" "}
                                        <img src={d.img} alt="..." className="team-img"/>
                                    </div>
                                </div>
                            ))
                        : "loading"}
                </Box>
            </Box>

            <Box className="col-md-8 col-md-offset-2" alignItems="center">
                {props.data
                    ? props.data
                        .filter(d => d.tier === 2)
                        .map((d, i) => (
                            <div key={`${d.name}-${i}`} className="col-md-4 col-sm-6 team">
                                <div className="thumbnail">
                                    {" "}
                                    <img src={d.img} alt="..." className="team-img"/>
                                </div>
                            </div>
                        ))
                    : "loading"}
            </Box>
        </Box>
    )
}

function TeamTeach(props) {
    return (
        <Box>
            <Box className="col-md-10 col-md-offset-1" alignItems="center">
                <Box alignItems="center">
                    {props.data
                        ? props.data
                            .filter(d => d.tier === 1)
                            .map((d, i) => (
                                <div key={`${d.name}-${i}`} className="col-md-6 col-sm-6 team">
                                    <div className="thumbnail">
                                        {" "}
                                        <img src={d.img} alt="..." className="team-img"/>
                                    </div>
                                </div>
                            ))
                        : "loading"}
                </Box>
            </Box>

            <Box className="col-md-10 col-md-offset-1" alignItems="center">
                <Box alignItems="center">
                    {props.data
                        ? props.data
                            .filter(d => d.tier !== 1)
                            .map((d, i) => (
                                <div key={`${d.name}-${i}`} className="col-md-6 col-sm-6 team">
                                    <div className="thumbnail">
                                        {" "}
                                        <img src={d.img} alt="..." className="team-img"/>
                                    </div>
                                </div>
                            ))
                        : "loading"}
                </Box>
            </Box>
        </Box>
    )
}

export const Team = (props) => {
    const {teamOps, teamTeach} = props;
    // State to track which component to display
    const [displayComponent, setDisplayComponent] = useState("Ops");

    // Function to handle button clicks
    const handleButtonClick = (componentName) => {
        setDisplayComponent(componentName);
    };

    // Define buttons with click handlers
    const buttons = [
        <Button variant="text" key="ops" onClick={() => handleButtonClick("Ops")}>
            <label className="tab-button">运营团队</label>
        </Button>,
        <Button variant="text" key="teach" onClick={() => handleButtonClick("Teach")}>
            <label className="tab-button">教学团队</label>
        </Button>
    ];


    const [teamValue, setValue] = React.useState("ops");

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box id="team" flexDirection="column" alignItems="center">
            <Box display="flex" className="col-md-12 section-title" alignItems="center"
                 flexDirection="column">
                <Box sx={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                    <Tabs
                        value={teamValue}
                        onChange={handleChange}
                        textColor="secondary"
                        indicatorColor="secondary"
                        aria-label="secondary tabs example"
                        sx={{
                            '& .MuiTab-root': {
                                fontSize: '36px', // Adjust the font size as needed
                                padding: '8px',
                                paddingLeft: '40px',
                                paddingRight: '40px'
                            },
                        }}
                    >
                        <Tab value="ops" label="运营团队"/>
                        <Tab value="teach" label="教学团队"/>
                    </Tabs>
                </Box>
            </Box>
            {teamValue === "teach" ?
                <TeamTeach data={teamTeach}/> :
                <TeamOps data={teamOps}/>}
        </Box>
    );
};
