import React from "react";

const initialState = {
    name: "",
    email: "",
    message: "",
};

const containerStyle = {
    display: 'flex',
    justifyContent: 'center', // Horizontal centering
    alignItems: 'center',     // Vertical centering
};

const ContactLogo = () => {
    return (
        <div className="container" style={containerStyle}>
            <img src="/img/logo_text_white.png" alt="Logo" className="contact-logo img-fluid"/>
        </div>

    )
}

function ContactInfo(props) {
    return (
        <div className="container contact-info-long" style={containerStyle}>
            <div>
                <div className="contact-item">
                    <p>
          <span>
            <i className="fa fa-map-marker"></i> Address
          </span>
                        {props.data ? props.data.address : "loading"}
                    </p>
                </div>
                <div className="contact-item">
                    <p>
          <span>
            <i className="fa fa-phone"></i> Phone
          </span>{" "}
                        {props.data ? props.data.phone : "loading"}
                    </p>
                </div>
                <div className="contact-item">
                    <p>
          <span>
            <i className="fa fa-envelope-o"></i> Email
          </span>{" "}
                        {props.data ? props.data.email : "loading"}
                    </p>
                </div>
            </div>
        </div>

    );
}

function SocialInfo(props) {
    return (
        <div className="container" style={containerStyle}>
            <div className="row">
                <div className="social">
                    <ul>
                        <li>
                            <img src="/img/qr/public_account_qr_white.png" alt="Your Image"/>
                            <p>微信公众号</p>
                        </li>
                        <li>
                            <img src="/img/qr/queen_qr.png" alt="Your Image"/>
                            <p>微信</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

const separatorStyle = {
    borderRight: '1px solid #111', // Adjust color and style as needed
    height: '80%', // Adjust height as needed
    margin: '0 10px', // Adjust margin as needed
};

export const Contact = (props) => {
    return (
        <div>
            <div id="contact">
                <div className="container">
                    <div className="contact-container">
                        <ContactLogo/>
                        <div className="contact-separator"></div>
                        <ContactInfo data={props.data}/>
                        <div className="contact-separator"></div>
                        <SocialInfo data={props.data}/>
                    </div>
                </div>
            </div>
            <div id="footer">
                <div className="container text-center">
                    <p>
                        EduPro Pte. Ltd. &copy; {new Date().getFullYear()} All Rights Reserved.
                    </p>
                </div>
            </div>
        </div>
    );
};
