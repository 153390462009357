import {Image} from "./image";
import React from "react";
import Box from '@mui/material/Box';

export const Gallery = (props) => {
    return (<Box id="portfolio" className="text-center">
        <Box className="container">
            <Box className="col-md-10 col-md-offset-1" alignItems="center">
                <h2>院校库</h2>
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit duis sed
                    dapibus leonec.
                </p>
            </Box>
            <Box className="row">
                <div className="portfolio-items">
                    {props.data ? props.data.map((d, i) => (<div
                        key={`${d.title}-${i}`}
                        className="col-sm-6 col-md-4 col-lg-4"
                    >
                        <Image
                            title={d.title}
                            largeImage={d.largeImage}
                            smallImage={d.smallImage}
                        />
                    </div>)) : "Loading..."}
                </div>
            </Box>
        </Box>
    </Box>);
};
